import { createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { deleteCDRSetting, enableCDRSettings, getCDRSettings } from './cdrsSettings.action';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';

export interface CDRItemType {
  id: number;
  enableForSuborgs: boolean;
  externalSystem: {
    id: number;
    name: string;
  };
  organization: {
    id: number;
    name: string;
  };
}

export interface CDRsSettingsState {
  cdrsSettingsList: null | PaginationType<CDRItemType>;
  cdrsSettingsFetchStatus: FETCH_STATUS;
  cdrsSettingsRouteParams: RouteParamsType;
  externalSystemInstances: Array<{ id: number; name: string }>;
  cdrsSettingsPostStatus: FETCH_STATUS;
  cdrsSettingsDeleteStatus: FETCH_STATUS;
}

export const initialCDRsSettingsRouteParams: RouteParamsType = {
  page: 1,
  limit: 10,
  order: 'organization.name',
  q: '',
};

const INIT_STATE: CDRsSettingsState = {
  cdrsSettingsList: null,
  cdrsSettingsFetchStatus: FETCH_STATUS.NULL,
  cdrsSettingsPostStatus: FETCH_STATUS.NULL,
  cdrsSettingsDeleteStatus: FETCH_STATUS.NULL,
  externalSystemInstances: [],
  cdrsSettingsRouteParams: initialCDRsSettingsRouteParams,
};

const cdrsSettingsSlice = createSlice({
  name: 'cdrsSettings',
  initialState: INIT_STATE,
  reducers: {
    setCDRsSettingsList: (state, action) => {
      state.cdrsSettingsList = action.payload;
    },
    setCDRsSettingsFetchStatus: (state, action) => {
      state.cdrsSettingsFetchStatus = action.payload;
    },
    setCDRsSettingsDeleteStatusList: (state, action) => {
      state.cdrsSettingsDeleteStatus = action.payload;
    },
    setCDRsSettingsPostStatusList: (state, action) => {
      state.cdrsSettingsPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'cdrsSettings') {
        state.cdrsSettingsRouteParams = { ...state.cdrsSettingsRouteParams, ...payload.data };
      }
    });
    builder.addCase(getCDRSettings.rejected, (state, action) => {
      state.cdrsSettingsFetchStatus = FETCH_STATUS.REJECTED;
      state.cdrsSettingsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCDRSettings.fulfilled, (state, action) => {
      state.cdrsSettingsFetchStatus = FETCH_STATUS.FULFILLED;
      state.cdrsSettingsList = {
        items: action.payload.items.map((item: any) => ({
          id: item.id,
          enableForSuborgs: item.enableForSuborgs,
          externalSystem: item.externalSystem,
          organization: item.organization,
        })),
        meta: action.payload.meta,
      };
    });
    builder.addCase(enableCDRSettings.pending, state => {
      state.cdrsSettingsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(enableCDRSettings.fulfilled, state => {
      state.cdrsSettingsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('CDR Setting created successfully.');
    });
    builder.addCase(enableCDRSettings.rejected, (state, action) => {
      state.cdrsSettingsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteCDRSetting.pending, state => {
      state.cdrsSettingsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteCDRSetting.fulfilled, (state, action) => {
      state.cdrsSettingsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('CDR Setting deleted successfully.');
      if (state.cdrsSettingsList) {
        state.cdrsSettingsList = {
          ...state.cdrsSettingsList,
          items: state.cdrsSettingsList.items.filter(item => item.id !== action.meta.arg),
        };
      }
    });
    builder.addCase(deleteCDRSetting.rejected, (state, action) => {
      state.cdrsSettingsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
  },
});

export const {
  setCDRsSettingsList,
  setCDRsSettingsFetchStatus,
  setCDRsSettingsDeleteStatusList,
  setCDRsSettingsPostStatusList,
} = cdrsSettingsSlice.actions;

export default cdrsSettingsSlice.reducer;
